import Shepherd from 'shepherd.js';
import Routes from '../../../constants/routes';

import welcomeTourSteps from './welcome-tour';
import newRequestSteps from './new-request-tour';
import viewRequestSteps from './view-request-tour';
import newQuestionProfileSteps from './new-question-profile';
import { track } from '../../../utils/analytics';

export const createNewTour = (defaultStepOptions = {}) =>
    new Shepherd.Tour({
        useModalOverlay: true,
        keyboardNavigation: false,
        exitOnEsc: false,
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
            when: {
                show: () => {
                    const currentStep = Shepherd.activeTour?.getCurrentStep();
                    const currentStepElement = currentStep?.getElement();
                    const header = currentStepElement?.querySelector('.shepherd-header');
                    const progress = document.createElement('span');

                    progress.classList.add('shepherd-progress');

                    progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1}/${
                        Shepherd.activeTour?.steps.length
                    }`;
                    header?.insertBefore(
                        progress,
                        currentStepElement.querySelector('.shepherd-cancel-icon')
                    );
                },
            },
            ...defaultStepOptions,
        },
    });

window.tours = {};

addEventListener('popstate', () => {
    if (Shepherd.activeTour) {
        Shepherd.activeTour.cancel();
    }
});

export function setupTours(formatMessage, updateTourState) {
    window.tours.welcomeTour = createNewTour({
        cancelIcon: {
            enabled: true,
        },
    });
    window.tours.newRequestTour = createNewTour();
    window.tours.viewRequestTour = createNewTour();
    window.tours.newQuestionProfileTour = createNewTour();

    const welcomeTour = window.tours.welcomeTour;
    const newRequestTour = window.tours.newRequestTour;
    const viewRequestTour = window.tours.viewRequestTour;
    const newQuestionProfileTour = window.tours.newQuestionProfileTour;

    //Welcome Tour Setup
    welcomeTour.addSteps(welcomeTourSteps(welcomeTour, formatMessage));
    welcomeTour.on('complete', () => {
        track('Completed Welcome Tour');
        updateTourState('welcomeTour', 'complete');
        window.TOUR_ACTIVE = false;
    });
    welcomeTour.on('cancel', ({ tour }) => {
        window.TOUR_ACTIVE = false;
        track('Cancelled Welcome Tour', {
            tourId: tour.currentStep.id,
        });
        updateTourState('welcomeTour', 'cancelled');
    });

    //New Request Tour Setup
    newRequestTour.addSteps(newRequestSteps(newRequestTour, formatMessage));
    newRequestTour.on('complete', () => {
        track('Completed New Request Tour');
        updateTourState('newRequest', 'complete');
    });
    newRequestTour.on('cancel', ({ tour }) => {
        track('Cancelled New Request Tour', {
            tourId: tour.currentStep.id,
        });
    });

    //View Request Tour Setup
    viewRequestTour.addSteps(viewRequestSteps(viewRequestTour, formatMessage));
    viewRequestTour.on('complete', () => {
        track('Completed View Request Tour');
        updateTourState('viewRequest', 'complete');
        window.TOUR_ACTIVE = false;
        setTimeout(() => location.assign(Routes.accountSetup));
    });
    viewRequestTour.on('cancel', ({ tour }) => {
        track('Cancelled View Request Tour', {
            tourId: tour.currentStep.id,
        });
        setTimeout(() => location.reload());
    });

    //Question Profile Tour Setup
    newQuestionProfileTour.addSteps(newQuestionProfileSteps(newQuestionProfileTour, formatMessage));
    newQuestionProfileTour.on('complete', () => {
        track('Completed Question Profile Tour');
        updateTourState('questionProfile', 'complete');
    });
    newQuestionProfileTour.on('cancel', ({ tour }) => {
        track('Cancelled Question Profile Tour', {
            tourId: tour.currentStep.id,
        });
    });
}

export function waitForElement(element, ms = 1000) {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            const target = document.getElementById(element);

            if (target) {
                clearInterval(interval);
                resolve();
            }
        }, ms);
    });
}
